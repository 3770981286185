@font-face {
	font-family: "Roboto Mono";
	font-weight: 400;
	src: url('./assets/fonts/RobotoMono-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	font-weight: 500;
	src: url('./assets/fonts/RobotoMono-Medium.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	font-weight: 500;
	font-style: italic;
	src: url('./assets/fonts/RobotoMono-MediumItalic.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	font-weight: 400;
	font-style: italic;
	src: url('./assets/fonts/RobotoMono-Italic.woff2') format('woff2');
	font-display: swap;
}

body {
	margin: 0;
	font-family: 'Roboto Mono', monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	min-height: 100%;
	background-color: black;
}

html {
	font-size: 16px;
}

@media screen and (min-width: 1600px) {
	html {
		font-size: 18px;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 1rem;
	line-height: 1.5rem;
}

.prompt__container {
	color: lightgray;
	margin: 4px 0;
	word-break: break-all;
	white-space: pre-line;
}

[contenteditable='true'],
[contenteditable='true']:focus,
[contenteditable='true']:focus-visible {
	outline: none;
}

ol {
	padding-left: 3rem;
	list-style-type: decimal;
}

ul {
	padding-left: 1rem;
	list-style-type: disc;
}

a {
	color: unset;
}
